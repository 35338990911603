import en from './en/translation.json'
import ch from './ch/translation.json'
import hi from './hi/translation.json'
import mar from './mar/translation.json'
import kan from './kan/translation.json'

const messages = {
  en,
  ch,
  mar,
  kan,
  hi

}

export default messages;