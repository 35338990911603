import React, { useContext, useEffect, useState } from "react";
import { Store } from "../Store";

import {
  addToOrder,
  clearOrder,
  listCategories,
  listProducts,
  removeFromOrder,
  getUserData,
} from "../actions";

import {
  Box,
  CircularProgress,
  Dialog,
  Slide,
} from "@material-ui/core";

import { useStyles } from "../styles";
import CartReview from "../components/Cart_Review";
import OrderCustomize from "../components/Order_Customize";
import CartButton from "../components/Cart_Button";
import BackButton from '../components/Back_Button';
import Categories from '../components/Categories';
import ProductList from '../components/Product_List';
import config, { getParameterByName, merchantCode } from "../Configs";


import { useIntl } from "react-intl";

export default function OrderScreen(props) {
  const styles = useStyles();
  const { formatMessage: t, locale, setLocale } = useIntl();
  const { state, dispatch } = useContext(Store);
  const { categories, loading, error } = state.categoryList;
  let {customizeInWizard, themeColor,themeTxtColor} = state;
  const [orderItem, setOrderItem] = useState(null);
  let pgSets = {customizeInWizard, themeColor,themeTxtColor};
  const {
    products,
    loading: loadingProducts,
    error: errorProducts,
  } = state.productList;

  const { orderItems, itemsCount, totalPrice, taxPrice, orderType } =
    state.order;
  const [quantity, setQuantity] = useState(1);
  const [selectedCat, setSelectedCat] = useState("");

  //const [isVegOnly, setIsVegOnly] = useState(false);

  //dialogs state
  const [isCustomizeOpen, setIsCustomizeOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const baseURL = config.baseURL;

  let { setting } = state.userSetting;
  let { userInfo } = state.userData;
  const selectedCurrency = state.selectedCurrency;
  console.log(orderType);
  if (setting) {
    let body = document.getElementsByTagName("body");
    //body[0].style.backgroundColor = setting.color_tirnary;
    setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);
  }

 useEffect(() => {
     listCategories(dispatch);
     listProducts(dispatch);
  }, []);

  const closeHandler = () => {
    setIsCustomizeOpen(false);
    setIsCartOpen(false);
  };

  const vegHandler = () => {
    console.log(products);
    //setIsVegOnly(!isVegOnly);
  };

const categoryClickHandler = (cat) => {
      console.log(cat);
      setSelectedCat(cat)
}

const addToOrderHandler = (customInstr) => {

  //Set price if variety price availabe
  let varName = Object.keys(orderItem.sub_pro.variety);
  orderItem.price = varName.length
      ? parseFloat(orderItem.sub_pro.variety[varName[0]])
      : orderItem.price;

  customInstr.length&& orderItem.sub_pro.cookInstructions.push(customInstr);
  console.log(orderItem);
  
  addToOrder(dispatch, { ...orderItem, quantity });

  setIsCustomizeOpen(false);
  setOrderItem(null);

  let isEditOrdItem=orderItems.filter(om=> om.id==orderItem.id).length;
  isEditOrdItem && setIsCartOpen(true);

  };



const productClickHandler = (p,isEditOrder) => {
    if(!isEditOrder){
    p['sub_pro']={};
    p.sub_pro['addons'] = [];
    p.sub_pro['variety'] = {};
    p.sub_pro['cookInstructions'] =[];
    }
    setOrderItem(p)
    setIsCustomizeOpen(!isCustomizeOpen);
  };

  const removeOrderItm = () => {
    setIsCustomizeOpen(false);
    setOrderItem(null);
    removeFromOrder(dispatch, orderItem);
  };

  const previewOrderHandler = () => {
    props.history.push("/complete?" + window.location.href.split("?")[1]);
  };

  const handleCart = (cartItems) => {
    console.log("Cart");
    if(cartItems==0)return
    setIsCartOpen(true);
  };

  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = "./images/blank.jpg";
  };

  const handleBack =()=>{
    if(isCartOpen||isCustomizeOpen){
        setIsCartOpen(false);
        setIsCustomizeOpen(false);
    }else{
    props.history.push(`/choose?` + window.location.href.split("?")[1]);
    }
  }

let slideChild=[];
 
 
  return (
    <Box className={styles.root} style={{ backgroundColor: "#fff" }}>
      <BackButton handleClick={()=>handleBack()}/>
      <Categories 
        categories={categories?categories:[]} 
        baseURL={baseURL}
        selectedCat={selectedCat}
        imageOnErrorHandler={imageOnErrorHandler}
        customizeInWizard={customizeInWizard}
        pgSets={pgSets}
        categoryClickHandler={categoryClickHandler}/>

      <h2>{(selectedCat && selectedCat.name) || t({ id: "explore_menu" })}</h2>
      
      <ProductList 
        items={products?products:[]} 
        baseURL={baseURL}
        pgSets={pgSets}
        imageOnErrorHandler={imageOnErrorHandler}
        productClickHandler={productClickHandler}
        categories={categories?categories:[]}
        selectedCat={selectedCat}
        t={t}/>
     
      <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
          style={{ backgroundColor: "#FFBC00 !important" }}
          open={isCartOpen||isCustomizeOpen}
          fullWidth={true}
        maxWidth={'xl'}
          
        >
        
         {isCustomizeOpen&& <OrderCustomize 
        orderItem={orderItem}
        setOrderItem={setOrderItem}
        baseURL={baseURL}
        imageOnErrorHandler={imageOnErrorHandler}
        quantity ={quantity}
        categories={categories}
        setQuantity={setQuantity}
        orderItems={orderItems}
        categories={categories}
        items={products?products:[]}
        setIsCustomizeOpen={setIsCustomizeOpen}
        addToOrderHandler={addToOrderHandler}
        removeOrderItm={removeOrderItm}
        t={t}
        />

        }
        {isCartOpen&& <CartReview t={t}  
        orderItems={orderItems}
        setIsCartOpen={setIsCartOpen}
        productClickHandler={productClickHandler}
        categories={categories}
        previewOrderHandler={previewOrderHandler}
        />}

        </Dialog>

        <CartButton itemsCount={itemsCount} 
        handleCart={handleCart}
        setIsCartOpen={setIsCartOpen}/>         
      </Box>
  );
}
