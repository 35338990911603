import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import html2canvas from "html2canvas";
import moment from "moment";
import config from "../Configs";
import CurrencySymbol from '../components/CurrencySymbol';
import { useIntl } from "react-intl";

function BillPrint(props) {
  const { formatMessage: t, locale, setLocale } = useIntl();
  const [loading, setLoading] = useState(true);
  const [print, setPrint] = useState(true);
  const orderId =props.orderDetails.orderId ;
  const currency =  props.orderDetails.currency; 
  const address = props.orderDetails.address; 
  const cgst = props.orderDetails.cgst;
  const invoiceNo = props.orderDetails.invoice_no;
  const merchantCode =  props.orderDetails.merchantCode
  const restaurant = props.orderDetails.restuarant
  const baseURL = config.baseURL;
  const billRef = useRef(null);
  const orderDetails = props.orderDetails;

  useEffect(() => {
       try{
      localStorage.setItem('isPrintCall', "Y");
      let bill= document.getElementById("bill");
      console.log(bill);
      if (bill) {
        html2canvas(bill).then((canvas) => {
          const canvasUrl = canvas.toDataURL("image/png");
          const base64Image = canvasUrl.split(",")[1];
          console.log(base64Image);
          if(window.PrintInterface){
             window.PrintInterface.print(base64Image);
          }
          window.chrome.webview.postMessage(base64Image);
          localStorage.setItem('isPrintCall', "N");
          props.setBillPrint(false);
        }).catch(error => {
          console.error("Error capturing the bill:", error);
        });
 
      }
    }catch(e){
      console.log("print error",e);
    }
  
}, [])

  
function handlePrint() {
  setPrint(false);

    const printButtonContainer = document.getElementById("print-container");
    if (printButtonContainer) {
      printButtonContainer.style.display = "none";
    }

    // if (window.PrintInterface && typeof window.PrintInterface.print === 'function') {
    if (window.PrintInterface) {
      window.PrintInterface.print();
      console.log("mobile");
    } else {
      console.log("No print support");
      window.print();
    }
  }


  const handleBack = () => {
    let isMerchant = sessionStorage.getItem("isMerchant");
    sessionStorage.setItem("billing", false);
    console.log(isMerchant);
    if (isMerchant) {
      window.location.href = `/epos?merchantCode=${merchantCode}`;
    } else {
      window.location.href = "/epos";
    }
  };
  // const [addressPart, gstPart, fssaiPart] = address.split(/GST:|FSSAI:/);
  // console.log(addressPart, gstPart, fssaiPart);
console.log(orderDetails)
  return (
    <div style={{ position: "absolute", zIndex: "-1" }}>       
        <div id="bill" >
          {orderDetails ? (
            <div
              className="container"
              style={{
                textAlign: "left",
                justifyContent: "start",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div id="orderDetails" style={{ textAlign: "left" }}>
                <h5 style={{ textAlign: "center",margin:"5px"}}>
                  Token #{orderDetails.number}
                </h5>
                <div
                  style={{
                    display: "none",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span id="status_chip">{t({ id: "preparing" })}</span>
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "9px",
                    fontWeight: "bold",
                  }}
                >
                 {t({ id: "order_summary" })}
                </p>
                <div
                  style={{
                    textAlign: "center",
                    lineHeight: "8px",
                    width: "100%",
                    fontSize: "9px",
                  }}
                >
                  <b>{restaurant}</b>
                  {/* <p>{addressPart.trim()}</p> */}
                  {/* <p>{`GST: ${gstPart ? gstPart.trim() : ""}`}</p> */}
                  {/* <p>{`FSSAI: ${fssaiPart ? fssaiPart.trim() : ""}`}</p> */}
                  <p style={{ display: "none" }}>TAX: {cgst}%</p>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    lineHeight: "8px",
                    width: "100%",
                    fontSize: "9px"
                  }}
                >
                  <p>{t({ id: "invoice" })}#: {invoiceNo}</p>
                  <p>
                    {moment(orderDetails.createdAt).format("DD-MM-YYYY h:mm a")}
                  </p>
                </div>
                <hr style={{ border: "1px solid black",margin:'5px' }} />
                <table
                  style={{ fontSize: "15px", lineHeight: "15px",fontSize: "9px", }}
                  align="center"
                  height="auto"
                  width="auto"
                  
                >
                  <thead align="center">
                    <tr style={{borderBottom:'2px dotted #000'}}>
                      <th align="start">{t({ id: "items" })}</th>
                      <th>{t({ id: "quantity" })}</th>
                      <th>{t({ id: "amount" })}</th>
                    </tr>
          
                  </thead>
                  <tbody align="center">
                    {orderDetails
                      ? orderDetails.orderItems.map((item, index) => {
                          const subProArray =
                            item.sub_pro && JSON.parse(item.sub_pro);
                          return (
                            <tr key={index} style={{borderBottom:'1px dotted #000'}}>
                              <td align="start">{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>
                              <CurrencySymbol/>
                                {item.price +
                                  (subProArray && subProArray.length
                                    ? subProArray.reduce(
                                        (acc, val) => acc + val.price,
                                        0
                                      )
                                    : 0)}
                              </td>
                              <td>
                                {item &&
                                item.status &&
                                item.status.toUpperCase() === "READY" ? (
                                  <input
                                    type="checkbox"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      accentColor: "#08eb0875",
                                    }}
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                    <tr>
                      <td colSpan="4">
                        <hr style={{ border: "1px solid black" }}></hr>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="end">
                        <b>{t({ id: "amount" })} :</b>
                      </td>
                      <td align="right">
                        {}{" "}
                        {orderDetails.totalPrice - orderDetails.taxPrice}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="end">
                        <b>{t({ id: "tax" })} ({cgst}%):</b>
                      </td>
                      <td align="right">
                         <CurrencySymbol/> {orderDetails.taxPrice}
                      </td>
                    </tr>
                    <tr>
                    </tr>
                  </tbody>
                </table>
                <p
                  align="center"
                  style={{
                    margin: "5px",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                  id="tot"
                >
                  {t({ id: "total" })}:  <CurrencySymbol/>{" "}
                  { orderDetails.totalPrice +
                      (orderDetails.totalPrice)}
                        
                </p>
              </div>
              <p style={{ textAlign: "center",fontSize:"9px" }}>
                {t({ id: "thanks_note" })} <br /> {t({ id: "thanks_note_1" })}
              </p>
              <p
                style={{
                  textAlign: "right",
                  marginTop: "10px",
                  float: "right",
                  fontSize:"11px"
                }}
              >
                <i>{t({ id: "poweredby" })}</i> <b>MenuLive</b>
              </p>
            </div>
          ):""}
        </div>

 

    </div>
  );
}

export default BillPrint;
